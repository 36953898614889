const SHARED_FAQ_LINKS = {
    SMETA (ScrollButton) {
        return <span>Certified by <ScrollButton dataSelector='overview-link' copy='SMETA (Sedex Members Ethical Trade Audit)' accordionContent='accordion-content-smeta' />, the world's leading social audit, which assesses factory standards of labor, health & safety, environmental, and business ethics</span>
    },
    GOODWEAVE (ScrollButton) {
        return <span><ScrollButton dataSelector='overview-link' copy='GoodWeave™ Certified' accordionContent='accordion-content-goodweave' />, ensuring that each rug is ethically crafted with no child labor, forced labor, or bonded labor used</span>
    },
    OEKO (ScrollButton) {
        return <span><ScrollButton dataSelector='overview-link' copy='OEKO-TEX® Standard 100 certified' accordionContent='accordion-content-what-is-oekotex' /></span>
    },
    SA8000 (ScrollButton) {
        return <span><ScrollButton dataSelector='overview-link' copy='SA8000 Social Accountability Certified' accordionContent='accordion-content-sa8000' /> to ensure fair and decent business practices for workers and demonstrate adherence to the highest social standards</span>
    },
    CERTIPUR (ScrollButton) {
        return <span><ScrollButton dataSelector='overview-link' copy='CertiPUR-US®' accordionContent='accordion-content-certipur' /> certified foams</span>
    },
    CENTIPUR_MEMORY_FOAM (ScrollButton) {
        return <span><ScrollButton dataSelector='overview-link' copy='CertiPUR-US®' accordionContent='accordion-content-certipur' /> certified memory foam</span>
    },
    GOTS (ScrollButton) {
        return <span><ScrollButton dataSelector='overview-link' copy='GOTS®' accordionContent='accordion-content-gots' /> (Global Organic Textile Standard) certified organic wool</span>
    },
    GOTS_COTTON(ScrollButton) {
        return <span><ScrollButton dataSelector='overview-link' copy='GOTS®' accordionContent='accordion-content-gots' /> certified organic cotton</span>
    },
    GOLS (ScrollButton) {
        return <span><ScrollButton dataSelector='overview-link' copy='GOLS' accordionContent='accordion-content-gols' /> (Global Organic Latex Standard) certified latex foam</span>
    },
    ECO_INSTITUT (ScrollButton) {
        return <span><ScrollButton dataSelector='overview-link' copy='eco-INSTITUT®' accordionContent='accordion-content-ecoinstitut' /> certified latex foam</span>
    },
    GREENGUARD_LATEX (ScrollButton) {
        return <span><ScrollButton dataSelector='overview-link' copy='GREENGUARD® Gold' accordionContent='accordion-content-greenguard' /> certified</span>
    },
    GREENGUARD (ScrollButton) {
        return <span><ScrollButton dataSelector='overview-link' copy='GREENGUARD® Gold' accordionContent='accordion-content-greenguard' /> certified</span>
    },
    CRADLE (ScrollButton) {
        return <span><ScrollButton dataSelector='overview-link' copy='Cradle to Cradle' accordionContent='accordion-content-cradle-to-cradle-gold-certified' /> GOLD Certified®</span>
    },
    FTS_COTTON (ScrollButton) {
        return <span><ScrollButton dataSelector='overview-link' copy='Fair Trade Certified' accordionContent='accordion-content-accordion-title-fair-trade-certified-cotton' /></span>
    }
}

export default SHARED_FAQ_LINKS
