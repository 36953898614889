import React from 'react'

import { PRODUCT_CODES as PC } from '@/constants'

import SHARED_LINKS from './sharedLinks'
import { anchorLink } from './sharedLinks'
import SHARED_FAQ_LINKS from './sharedFaqLinks'
import * as UI from './sharedUiComponents'
import InfoTooltip from '@/components/InfoTooltip'
import styles from '../ProductOverviewAccordion.module.scss'

/*
This config should be imported into the component for use.
Any named files in this directory are intended as reusable units exclusively for this file.
*/

/*
Content has been designed to take standard HTML tags and format them consistently.
The currently styled and supported tags for the content elements are:
- h4 (headings within each accordion section/column)
- p
- ul, li
- em, strong, small (reduces text size, like for "* disclaimers")

The element(s) returned by `getContent` is flex displayed by its container.
- In desktop, elements will be distributed horizontally.
- In tablet/mobile, elements will be stacked vertically.
Accordions with a single section must wrap it's getContent result in a true element.
    - ie: `<div>...</div>`, `<ul><li>...</li></ul>`, etc
To display multiple sections flexed properly, multiple elements may be returned wrapped in an outer react fragment:
    - ie: `
        <>
            <div>Flexed Section One Content...</div>
            <div>Flexed Section Two Content...</div>
        </>
    `
*/

const COMPATIBILITY_STANDARD_MATTRESS = (adjustableBaseExtraData) => [ 
    {
        title: 'Mattress foundation or box spring',
        body: <>
            Place on a {SHARED_LINKS.FOUNDATION} on its own or with the optional universal frame to raise the foundation off the floor
        </>
    },
    {
        title: 'Standard bed frame + foundation or box spring',
        body: <>
            Place on a {SHARED_LINKS.STANDARD_BEDFRAME} with a required foundation or box spring
        </>
    },
    {
        title: 'Platform bed frame',
        body: <>
            Place on a {SHARED_LINKS.PLATFORM_BEDFRAME} with either a solid surface or slatted platform with slats that are at least 3" wide and spaced less than 4" apart
        </>
    },
    {
        title: 'Adjustable base*',
        body: <>
            {adjustableBaseExtraData} Place on an {SHARED_LINKS.ADJUSTABLE_BASE} to elevate head and feet for the most customized comfort
        </>
    }
]

const COMPATIBILITY_SAATVA_HD = [ 
    {
        title: 'Saatva HD Foundation (sold separately) *',
        body: <>
            Place on the durable HD foundation on its own or with a standard bed frame that requires a foundation
        </>
    },
    {
        title: 'Standard bed frame + foundation or box spring',
        body: <>
            Place on a {SHARED_LINKS.STANDARD_BEDFRAME} with a required foundation
        </>
    },
    {
        title: 'Platform bed frame',
        body: <>
            Place on a {SHARED_LINKS.PLATFORM_BEDFRAME} with either a solid surface or slatted platform with slats that are at least 3" wide and spaced less than 4" apart
        </>
    },
    {
        title: 'Adjustable base*',
        body: <>
            Place on an {SHARED_LINKS.ADJUSTABLE_BASE} to elevate head and feet for the most customized comfort
        </>
    }
]

const COMPATIBILITY_SAATVA_YOUTH = [ 
    {
        title: 'Mattress foundation or box spring',
        body: <>
            Place on a {SHARED_LINKS.FOUNDATION} on its own or with the optional universal frame to raise the foundation off the floor
        </>
    },
    {
        title: 'Standard bed frame + foundation or box spring',
        body: <>
            Place on a {SHARED_LINKS.STANDARD_BEDFRAME} with a required foundation or box spring 
        </>
    },
    {
        title: 'Platform bed frame',
        body: <>
            Place on a {SHARED_LINKS.PLATFORM_BEDFRAME} with a solid surface or on plywood, masonite board, or bunkie board placed over the slats to protect the dual-sided mattress
        </>
    }
]

const COMPATIBILITY_ZENHAVEN = [ 
    {
        title: 'Mattress foundation or box spring',
        body: <>
            Place on a {SHARED_LINKS.FOUNDATION} on its own or with the optional universal frame to raise the foundation off the floor
        </>
    },
    {
        title: 'Standard bed frame + foundation or box spring',
        body: <>
            Place on a {SHARED_LINKS.STANDARD_BEDFRAME} with a required foundation or box spring 
        </>
    },
    {
        title: <>
            <div class={styles.compatibilityTooltip}><span>Platform bed frame </span><InfoTooltip direction='left' className={`${styles.tooltip} ${styles.inlineTooltip}`} contentClassName={styles.tooltipContent}>
            Placing the Zenhaven over slats without a solid surface may void the lifetime warranty. 
            </InfoTooltip></div>
        </>,
        body: <>
            Place on a {SHARED_LINKS.PLATFORM_BEDFRAME}  with a solid surface or on plywood, masonite board, or bunkie board placed over the slats to protect the dual-sided mattress
        </>
    },
    {
        title: 'Adjustable base*',
        body: <>
            Place on a {SHARED_LINKS.ADJUSTABLE_BASE} to elevate head and feet for the most customized comfort
        </>
    }
]

const COMPATIBILITY_BUNK_TRUNDLE = [ 
    {
        title: 'Bunk beds',
        body: <>
            Fits on the top bunk without sitting over the guard rails
        </>
    },
    {
        title: 'Trundle beds',
        body: <>
            Fits under the main bed of roll-out or pop-up trundle beds 
        </>
    }
]

const config = {

    // MATTRESSES
    [PC.SAATVA_CLASSIC]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-luxury', title: 'Luxurious'},
                        {spriteID: 'icon-durable-mattress', title: 'Durable'},
                        {spriteID: 'icon-supportive', title: 'Supportive'},
                        {spriteID: 'icon-breathable-mattress', title: 'Breathable'}
                    ]}/>
                    <ul>
                        <li>Luxurious 3” Euro pillow top is available in three comfort levels for cushioned comfort and instant pressure relief</li>
                        <li>Durable and responsive dual-coil design contours to your curves to relieve pressure points, allows great airflow, and prevents sagging over time</li>
                        <li>Patented Lumbar Zone® Technology supports healthy spinal alignment to help relieve back pain</li>
                        <li>Cover made with naturally hypoallergenic and breathable organic cotton for cooler sleep</li>
                        <li>Exclusive Guardin™ botanical antimicrobial treatment inhibits the growth of bacteria, mold, and mildew</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Firmness',
            subtitle: 'Available in three comfort levels:',
            getContent: () => (
                <div>
                    <p class='u-paddingBottom--lg'>Available in three comfort levels:</p>
                    <div class={styles.accordionTitleContainerTitle}>
                        <UI.FirmnessBlock spriteID='icon-firmness-3' title='Plush Soft' afterTitle='(3/10)'>
                            <ul>
                                <li>Contouring, body-hugging feel </li>
                                <li>Best suited for side sleepers and lighter weight sleepers</li>
                            </ul>
                        </UI.FirmnessBlock>
                        <UI.FirmnessBlock spriteID='icon-firmness-5-7' title='Luxury Firm' afterTitle='(5-7/10)'>
                            <ul>
                                <li>Our most popular comfort level</li>
                                <li>Ideal for couples with different firmness preferences and sleep styles</li>
                                <li>Best suited for back sleepers, but also great for stomach, and side sleepers</li>
                            </ul>
                        </UI.FirmnessBlock>
                        <UI.FirmnessBlock spriteID='icon-firmness-8' title='Firm' afterTitle='(8/10)'>
                            <ul>
                                <li>"Floating on top" feel</li>
                                <li>Best suited for stomach sleepers, but also great for back sleepers who prefer a very firm mattress</li>
                                <li>Ideal for heavier weight sleepers 250-300 pounds</li>
                            </ul>
                        </UI.FirmnessBlock>
                    </div>
                </div>
            )
        },
        {
            heading: 'Compatibility',
            getContent: () => (
                <div>
                    <UI.CompatibleItems itemsData={COMPATIBILITY_STANDARD_MATTRESS('(11.5” height only)')} />
                    <UI.Disclaimer>
                        *The Saatva Classic 11.5" Split King & Split Cal King pair exclusively with our {SHARED_LINKS.ADJUSTABLE_BASE_SPLIT_KINGS}
                    </UI.Disclaimer>
                </div>
            )
        },
        
        {
            heading: 'Certifications & Awards',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-certipur-us',
                        'icon-certification-greenguard',
                        'icon-certification-ccsa',
                        'icon-certification-aca'
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.CERTIPUR(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.GREENGUARD_LATEX(ScrollToFaqsAndSpecsButton)}</li>
                        <li>Congress of Chiropractic State Association Seal of Approval</li>
                        <li>Endorsed by the American Chiropractic Association</li>
                    </ul>

                    <UI.Disclaimer>
                        * CertiPUR-US® is a registered trademark of the Alliance for Flexible Polyurethane Foam, Inc.
                    </UI.Disclaimer>
                </div>
            )
        },
    ],
    [PC.SAATVA_RX]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-contouring', title: 'Body-contouring'},
                        {spriteID: 'icon-durable-mattress', title: 'Enhanced back support'},
                        {spriteID: 'icon-responsive', title: 'Responsive'},
                        {spriteID: 'icon-breathable-mattress', title: 'Breathable'}
                    ]}/>
                    <ul>
                        <li>Specially designed for maximum support and pressure relief to help alleviate discomfort associated with <strong>serious and chronic back & joint conditions like sciatica, arthritis, herniated disc, and scoliosis</strong></li>
                        <li><strong>Therapeutic Support Core™</strong> automatically adjusts to your body’s curves and movements, virtually eliminating pressure points for better circulation and more restorative sleep</li>
                        <li>Patented Lumbar Zone® Quilting & gel-infused memory foam help relieve back pain by keeping your spine in healthy alignment</li>
                        <li>Exclusive Guardin™ botanical antimicrobial treatment inhibits the growth of bacteria, mold, and mildew</li>
                    </ul>
                    <UI.Callout>
                        For normal aches & pains, we recommend our best-selling {SHARED_LINKS.SAATVA_CLASSIC}, available in 3 comfort options
                    </UI.Callout>
                </div>
            )
        },
        {
            heading: 'Firmness',
            getContent: () => (
                <>
                    <UI.FirmnessBlock spriteID='icon-firmness-3-4' title='Supportive Plush' afterTitle='(3-4/10)'>
                        <ul>
                            <li>Plush sleep surface instantly relieves pressure points</li>
                            <li>Supportive core keeps the spine in perfect, healthy alignment</li>
                            <li>Best suited for side sleepers, but also great for back and combination sleepers, and stomach sleepers under 130 pounds</li>
                        </ul>
                    </UI.FirmnessBlock>
                </>
            )
        },
        {
            heading: 'Compatibility',
            getContent: () => (
                <div>
                    <UI.CompatibleItems itemsData={COMPATIBILITY_STANDARD_MATTRESS()} />
                    <UI.Disclaimer>
                        * Saatva Rx Split King pairs exclusively with our {SHARED_LINKS.ADJUSTABLE_BASE_SPLIT_KING}
                    </UI.Disclaimer>
                </div>
            )
        },
        {
            heading: 'Certifications & Awards',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-certipur-us',
                        'icon-certification-greenguard',
                        'icon-certification-ccsa',
                        'icon-certification-aca',
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.CERTIPUR(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.GREENGUARD_LATEX(ScrollToFaqsAndSpecsButton)}</li>
                        <li>Congress of Chiropractic State Association Seal of Approval</li>
                        <li>Endorsed by the American Chiropractic Association</li>
                    </ul>
                    <UI.Disclaimer>
                        * CertiPUR-US® is a registered trademark of the Alliance for Flexible Polyurethane Foam, Inc.
                    </UI.Disclaimer>
                </div>
            )
        }
    ],
    [PC.SAATVA_LATEX_HYBRID]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-pressure-relief', title: 'Pressure relieving'},
                        {spriteID: 'icon-responsive', title: 'Responsive'},
                        {spriteID: 'icon-cooling', title: 'Excellent cooling'},
                        {spriteID: 'icon-healthier-sleep', title: 'Healthier sleep'}
                    ]}/>
                    <ul>
                        <li>Hand-tufted for durability with a cover made with naturally breathable organic cotton</li>
                        <li>Ergonomic 5-zone organic natural latex foam layer with vented airflow channels for a buoyant feel, superior pressure relief, breathability, and enhanced lumbar support</li>
                        <li>Individually wrapped coils contour and respond to your body’s curves and movements</li>
                        <li>Sleeps cooler with organic natural latex, cover made with organic cotton, and organic wool</li>
                        <li>Exclusive Guardin™ botanical antimicrobial treatment inhibits the growth of bacteria, mold, and mildew</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Firmness',
            getContent: () => (
                <>
                    <UI.FirmnessBlock spriteID='icon-firmness-6' title='Medium-Firm' afterTitle='(6/10)'>
                        <ul>
                            <li>Ideal for couples with different firmness preferences and sleep styles</li>
                            <li>Offers an optimal balance of responsive support and a buoyant feel</li>
                            <li>Ideal for all sleep positions and best suited for back and stomach sleepers</li>
                        </ul>
                    </UI.FirmnessBlock>
                </>
            )
        },
        {
            heading: 'Compatibility',
            getContent: () => (
                <div>
                    <UI.CompatibleItems itemsData={COMPATIBILITY_STANDARD_MATTRESS()} />
                    <UI.Disclaimer>
                        * GOTS® (Global Organic Textile Standard) is a registered trademark of Global Standard gGmbH
                    </UI.Disclaimer>
                    <UI.Disclaimer>
                        The GOLS picture-mark ( “GOLS logo” or “GOLS label”) is copyright material and is a registered trademark owned by the Control Union Certifications B.V
                    </UI.Disclaimer>
                    <UI.Disclaimer>
                        eco-INSTITUT® is a registered trademark of eco-INSTITUT Germany GmbH
                    </UI.Disclaimer>
                    <UI.Disclaimer>
                        UL GREENGUARD is a trademark of UL LLC
                    </UI.Disclaimer>
                </div>
            )
        },
        {
            heading: 'Certifications & Awards',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-gots',
                        'icon-certification-gols',
                        'icon-certification-eco-institut',
                        'icon-certification-greenguard',
                        'icon-certification-ccsa',
                        'icon-certification-aca'
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.GOTS(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.GOLS(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.ECO_INSTITUT(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.GREENGUARD_LATEX(ScrollToFaqsAndSpecsButton)}</li>
                        <li>Congress of Chiropractic State Association Seal of Approval</li>
                        <li>Endorsed by the American Chiropractic Association</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.MEMORY_FOAM_HYBRID]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-supportive', title: 'Body-contouring'},
                        {spriteID: 'icon-responsive', title: 'Responsive'},
                        {spriteID: 'icon-cooling', title: 'Triple cooling'},
                        {spriteID: 'icon-lumbar-support', title: 'Back support'}
                    ]}/>
                    <ul>
                        <li>Premium <UI.ScrollLink targetID='triple-phase'>AirCradle™ memory foam</UI.ScrollLink> for a body-contouring feel & excellent pressure relief</li>
                        <li>Durable individually wrapped coils for a responsive feel & greater ease of movement</li>
                        <li>Proprietary <UI.ScrollLink targetID='triple-phase'>LuxeCool™ system</UI.ScrollLink> allows for great airflow & actively draws away body heat to keep you cooler than regular memory foam mattresses</li>
                        <li>Patented Lumbar Zone® Quilting & gel-infused memory foam help relieve back pain by keeping your spine in healthy alignment</li>
                        <li>Exclusive Guardin™ botanical antimicrobial treatment to inhibit the growth of bacteria, mold, and mildew</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Firmness',
            getContent: () => (
                <>
                    <UI.FirmnessBlock spriteID='icon-firmness-5-7' title='Medium' afterTitle='(5-7/10)'>
                        <ul>
                            <li>Ideal for couples with different firmness preferences</li>
                            <li>Best suited for side sleepers, but also great for back and combination sleepers</li>
                        </ul>
                    </UI.FirmnessBlock>
                </>
            )
        },
        {
            heading: 'Compatibility',
            getContent: () => (
                <div>
                    <UI.CompatibleItems itemsData={COMPATIBILITY_STANDARD_MATTRESS()} />
                    <UI.Disclaimer>
                        * Memory Foam Hybrid Split King pairs best with our {SHARED_LINKS.ADJUSTABLE_BASE_SPLIT_KING}
                    </UI.Disclaimer>
                </div>
            )
        },
        {
            heading: 'Certifications & Awards',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-certipur-us',
                        'icon-certification-greenguard',
                        'icon-certification-ccsa',
                        'icon-certification-aca',
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.CERTIPUR(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.GREENGUARD_LATEX(ScrollToFaqsAndSpecsButton)}</li>
                        <li>Congress of Chiropractic State Association Seal of Approval</li>
                        <li>Endorsed by the American Chiropractic Association</li>
                    </ul>

                    <UI.Disclaimer>
                        * CertiPUR-US® is a registered trademark of the Alliance for Flexible Polyurethane Foam, Inc.
                    </UI.Disclaimer>
                </div>
            )
        }
    ],
    [PC.SOLAIRE]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-personalized', title: 'Personalized comfort'},
                        {spriteID: 'icon-motion-isolation', title: 'Great for couples'},
                        {spriteID: 'icon-luxury', title: 'Luxurious'},
                        {spriteID: 'icon-breathable-mattress', title: 'Breathable'}
                    ]}/>
                    <ul>
                        <li>50 precise firmness settings on both sides (in Queen or larger)</li>
                        <li>Virtually no motion transfer (great for couples)</li>
                        <li>Excellent pressure relief in any firmness setting</li>
                        <li>Comfort layers are flippable to have either the natural latex side up for a more responsive feel or the memory foam side up for a more body-hugging feel</li>
                        <li>Quiet, streamlined components</li>
                        <li>Cover made with naturally hypoallergenic and breathable organic cotton for cooler sleep</li>
                        <li>Exclusive Guardin™ botanical antimicrobial treatment inhibits the growth of bacteria, mold, and mildew</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Firmness',
            getContent: () => (
                <>
                    <UI.FirmnessBlock spriteID='icon-firmness-50' title='50 Firmness Settings'>
                        <ul>
                            <li>Ideal for couples with different firmness preferences</li>
                            <li>Ideal for all sleep positions, especially side sleepers</li>
                            <li>Ideal for sleepers whose comfort needs can change from day to day due to pregnancy, injuries, recurring joint inflammation, etc.</li>
                        </ul>
                    </UI.FirmnessBlock>
                </>
            )
        },
        {
            heading: 'Compatibility',
            getContent: () => (
                <div>
                    <UI.CompatibleItems itemsData={COMPATIBILITY_STANDARD_MATTRESS()} />
                    <UI.Disclaimer>
                        * The Solaire Upper-Flex pairs exclusively with our {SHARED_LINKS.ADJUSTABLE_BASE_UF_KING}
                    </UI.Disclaimer>
                    <UI.Disclaimer>
                        * Solaire Split King & Split Cal King pair exclusively with our {SHARED_LINKS.ADJUSTABLE_BASE_SPLIT_KINGS}
                    </UI.Disclaimer>
                </div>
            )
        },
        {
            heading: 'Certifications & Awards',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-certipur-us',
                        'icon-certification-ccsa',
                        'icon-certification-aca'
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.CERTIPUR(ScrollToFaqsAndSpecsButton)}</li>
                        <li>Congress of Chiropractic State Association Seal of Approval</li>
                        <li>Endorsed by the American Chiropractic Association</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.SAATVA_CONTOUR5]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-pressure-relief', title: 'Pressure relief'},
                        {spriteID: 'icon-cooling', title: 'Adaptive cooling'},
                        {spriteID: 'icon-contouring', title: 'Body-hugging feel'},
                        {spriteID: 'icon-world-good', title: 'Healthier sleep'}
                    ]}/>
                    <ul>
                        <li>Luxurious body-hugging feel and pressure relief with 3” of ultra-premium, high-density 5lb memory foam</li>
                        <li>Adaptive cooling technology keeps your body at optimal temperature while providing enhanced lumbar support for more restful sleep</li>
                        <li>Excellent motion isolation for couples</li>
                        <li>All-foam construction minimizes dust mite allergens for a more hypoallergenic sleep environment</li>
                        <li>Exclusive Guardin™ botanical antimicrobial treatment inhibits the growth of bacteria, mold, and mildew</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Firmness',
            subtitle: 'Available in two comfort levels:',
            getContent: () => (
                <div>
                    <p class='u-paddingBottom--lg'>Available in two comfort levels:</p>
                    <div class={styles.accordionTitleContainerTitle}>
                        <UI.FirmnessBlock spriteID='icon-firmness-5-7' title='Medium (5-7/10)'>
                            <ul>
                                <li>Ideal for side sleepers, but also great for back and combination sleepers</li>
                                <li>Balance of body-hugging comfort and adequate support</li>
                                <li>Ideal for couples with different firmness preferences and sleep styles</li>
                            </ul>
                        </UI.FirmnessBlock>
                        <UI.FirmnessBlock spriteID='icon-firmness-8' title='Firm (8/10)'>
                            <ul>
                                <li>Firmer foams offer less of a body-hugging feel & more responsiveness</li>
                                <li>Ideal for back and stomach sleepers or those who need or prefer a firm mattress</li>
                                <li>Ideal for heavier weight sleepers over 230 pounds</li>
                                <li>Balance of body-contouring comfort and firm support</li>
                            </ul>
                        </UI.FirmnessBlock>
                    </div>
                </div>
            )
        },
        {
            heading: 'Compatibility',
            getContent: () => (
                <div>
                    <UI.CompatibleItems itemsData={COMPATIBILITY_STANDARD_MATTRESS()} />
                    <UI.Disclaimer>
                        * Contour5 Split King pairs exclusively with our {SHARED_LINKS.ADJUSTABLE_BASE_SPLIT_KING}
                    </UI.Disclaimer>
                </div>
            )
        },
        {
            heading: 'Certifications & Awards',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-certipur-us',
                        'icon-certification-greenguard',
                        'icon-certification-ccsa',
                        'icon-certification-aca',
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.CERTIPUR(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.GREENGUARD_LATEX(ScrollToFaqsAndSpecsButton)}</li>
                        <li>Congress of Chiropractic State Association Seal of Approval</li>
                        <li>Endorsed by the American Chiropractic Association</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.SAATVA_HD]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-luxury', title: 'Luxurious'},
                        {spriteID: 'icon-durable-mattress', title: 'Extra Durable'},
                        {spriteID: 'icon-supportive', title: 'Extra Supportive'},
                        {spriteID: 'icon-breathable-mattress', title: 'Breathable'}
                    ]}/>
                    <ul>
                        <li>3" Euro pillow top with a hand-tufted finish offers plush, cushioned comfort for every body type</li>
                        <li>Engineered to comfortably support sleepers up to 500 lbs with a high-durability coil base</li>
                        <li>Patented Lumbar Zone® Technology supports healthy spinal alignment to help relieve back pain</li>
                        <li>Sleeps cooler with natural latex and a breathable cover made with organic cotton</li>
                        <li>Exclusive Guardin™ botanical antimicrobial treatment inhibits the growth of bacteria, mold, and mildew</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Firmness',
            getContent: () => (
                <>
                    <UI.FirmnessBlock spriteID='icon-firmness-5-7' title='Medium-Firm* (5-7/10)'>
                        <ul>
                            <li>Optimal balance of cushioned comfort and durable support</li>
                            <li>Most comparable to our best-selling Saatva Classic Luxury Firm in comfort level</li>
                            <li>Best suited for back sleepers, but also great for combination & stomach sleepers</li>
                        </ul>
                        <UI.Disclaimer>
                            * Based on heavier sleepers weighing 300 to 500 pounds. Sleepers under 300 pounds will find Saatva HD to be firmer in feel.
                        </UI.Disclaimer>
                    </UI.FirmnessBlock>

                </>
            )
        },
        {
            heading: 'Compatibility',
            getContent: () => (
                <div>
                    <UI.CompatibleItems itemsData={COMPATIBILITY_SAATVA_HD} />
                    <UI.Disclaimer>
                        * Saatva HD Split King pairs exclusively with our {SHARED_LINKS.ADJUSTABLE_BASE_SPLIT_KING}
                    </UI.Disclaimer>
                    <UI.Disclaimer>
                        * Saatva HD is NOT compatible with regular foundations or box springs. Our HD foundation was designed to pair exclusively with the Saatva HD. 
                    </UI.Disclaimer>
                </div>
            )
        },
        {
            heading: 'Certifications & Awards',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-certipur-us',
                        'icon-certification-greenguard',
                        'icon-certification-ccsa',
                        'icon-certification-aca',
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.CERTIPUR(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.GREENGUARD_LATEX(ScrollToFaqsAndSpecsButton)}</li>
                        <li>Congress of Chiropractic State Association Seal of Approval</li>
                        <li>Endorsed by the American Chiropractic Association</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.SAATVA_YOUTH]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-dual-sided', title: 'Dual sided'},
                        {spriteID: 'icon-water-proof', title: 'Accident-proof'},
                        {spriteID: 'icon-healthier-sleep', title: 'Healthier sleep'},
                    ]}/>
                    <ul>
                        <li>Dual-sided to adapt to your growing child’s needs from ages 3-12</li>
                        <li>Lumbar Zone® Quilting for enhanced lower back support</li>
                        <li>Enhanced support at the center third on both sides of the mattress for proper spinal alignment at every stage of growth</li>
                        <li>Dual-sided coil unit is strong yet flexible to conform to growing bodies</li>
                        <li>Cover made with naturally hypoallergenic and breathable organic cotton for cooler sleep</li>
                        <li>Exclusive Guardin™ botanical antimicrobial treatment inhibits the growth of bacteria, mold, and mildew</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Firmness',
            subtitle: 'Flippable, dual-sided design',
            getContent: () => (
                <div>
                    <p class='u-paddingBottom--lg'>Flippable, dual-sided design:</p>
                    <div class={styles.accordionTitleContainerTitle}>
                        <UI.FirmnessBlock spriteID='icon-firmness-3' title='Age 3-7 Side: Plush Soft'>
                            <ul>
                                <li>Designed for younger kids who need flexibility with proper spinal support</li>
                            </ul>
                        </UI.FirmnessBlock>
                        <UI.FirmnessBlock spriteID='icon-firmness-5-7' title='Age 8-12 Side: Medium-Firm'>
                            <ul>
                                <li>Firmer support that maintains proper spinal alignment in older kids</li>
                            </ul>
                        </UI.FirmnessBlock>
                    </div>
                </div>
            )
        },
        {
            heading: 'Compatibility',
            getContent: () => (
                <div>
                    <UI.CompatibleItems itemsData={COMPATIBILITY_SAATVA_YOUTH} />
                </div>
            )
        },
        {
            heading: 'Certifications & Awards',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-certipur-us',
                        'icon-certification-greenguard',
                        'icon-certification-ccsa',
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.CERTIPUR(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.GREENGUARD_LATEX(ScrollToFaqsAndSpecsButton)}</li>
                        <li>Congress of Chiropractic State Association Seal of Approval</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.ZENHAVEN]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-dual-sided', title: 'Dual sided'},
                        {spriteID: 'icon-pressure-relief', title: 'Pressure relieving'},
                        {spriteID: 'icon-cooling', title: 'Excellent cooling'},
                        {spriteID: 'icon-healthier-sleep', title: 'Healthier sleep'}
                    ]}/>
                    <ul>
                        <li>Ergonomic <UI.ScrollLink targetID='five-zone'>5-zone natural latex foam</UI.ScrollLink> with vented airflow channels offers a buoyant feel, superior pressure relief, superb breathability, and enhanced lumbar support</li>
                        <li>Flippable, <UI.ScrollLink targetID='dual-sided-comfort'>dual-sided design</UI.ScrollLink> allows you to choose the firmness level that suits you</li>
                        <li>Cover made with naturally hypoallergenic and breathable organic cotton for cooler sleep</li>
                        <li>All-foam construction minimizes dust mite allergens for a more hypoallergenic sleep environment</li>
                        <li>Organic natural latex is naturally very breathable and organic wool helps regulate body temperature for more comfortable sleep</li>
                        <li>Exclusive Guardin™ botanical antimicrobial treatment inhibits the growth of bacteria, mold, and mildew</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Firmness',
            subtitle: 'Flippable, dual-sided design:',
            getContent: () => (
                <div>
                    <p class='u-paddingBottom--lg'>Flippable, dual-sided design:</p>
                    <div class={styles.accordionTitleContainerTitle}>
                        <UI.FirmnessBlock spriteID='icon-firmness-4-5' title='Luxury Plush (Medium, 4-5/10)'>
                            <ul>
                                <li>Best suited for side sleepers, but also great for back & combination sleepers</li>
                                <li>Offers more body contouring than the firm side to relieve pressure points on hips and shoulders</li>
                            </ul>
                        </UI.FirmnessBlock>
                        <UI.FirmnessBlock spriteID='icon-firmness-7-8' title='Gentle Firm (Firm, 7-8/10)'>
                            <ul>
                                <li>18% firmer than the Luxury Plush side</li>
                                <li>Best suited for stomach sleepers, but also great for back sleepers</li>
                            </ul>
                        </UI.FirmnessBlock>
                    </div>
                </div>
            )
        },
        {
            heading: 'Compatibility',
            getContent: () => (
                <div>
                    <UI.CompatibleItems itemsData={COMPATIBILITY_ZENHAVEN} />
                    <UI.Disclaimer>
                        * Zenhaven Upper-Flex King pairs exclusively with our {SHARED_LINKS.ADJUSTABLE_BASE_UF_KING_BUNDLE} 
                    </UI.Disclaimer>
                    <UI.Disclaimer>
                        Zenhaven Split King pairs exclusively with our {SHARED_LINKS.ADJUSTABLE_BASE_SPLIT_KING}
                    </UI.Disclaimer>
                </div>
            )
        },
        {
            heading: 'Certifications & Awards',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-gots',
                        'icon-certification-gols',
                        'icon-certification-eco-institut',
                        'icon-certification-greenguard',
                        'icon-certification-ccsa',
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.GOTS(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.GOLS(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.ECO_INSTITUT(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.GREENGUARD_LATEX(ScrollToFaqsAndSpecsButton)}</li>
                        <li>Congress of Chiropractic State Association Seal of Approval</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.CRIB_MATTRESS]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-dual-sided', title: 'Dual sided'},
                        {spriteID: 'icon-healthier-sleep', title: 'Healthier sleep'},
                        {spriteID: 'icon-water-proof', title: 'Accident-proof'},
                        {spriteID: 'icon-breathable-mattress', title: 'Breathable'}
                    ]}/>
                    <ul>
                        <li>Dual-sided to adapt to your growing baby through their toddler years</li>
                        <li>Reinforced with double edge support to keep your baby or toddler safely centered on the crib</li>
                        <li>Removable and washable cover made with organic cotton and treated with an eco-friendly water-repellent treatment</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Dimensions',
            getContent: () => (
                <div>
                    <ul>
                        <li><strong>Standard crib</strong> - 28" x 52"</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Firmness',
            subtitle: 'Flippable, dual-sided design:',
            getContent: () => (
                <>
                    <div>
                        <h3>Flippable, dual-sided design</h3>
                        <br/>
                        <div>
                            <h3><strong>Baby Side: Extra Firm</strong></h3>
                            <ul>
                                <li>Designed to give infants extra firm support recommended by physicians to help prevent SIDS and other sleep-related accidents</li>
                            </ul>
                        </div>
                        <div>
                            <h3><strong>Toddler Side: Firm</strong></h3>
                            <ul>
                                <li>Designed to give optimal, responsive support for active toddlers</li>
                            </ul>
                        </div>
                    </div>
                </>
            )
        },
        {
            heading: 'Certifications & Awards',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-gots',
                        'icon-certification-greenguard',
                        'icon-certification-cradletocradle'
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.GOTS(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.GREENGUARD_LATEX(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.CRADLE(ScrollToFaqsAndSpecsButton)}</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.CLASSIC_STARTER_BUNDLE]: [
        {
            heading: 'Saatva Classic Mattress',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <h3 className='t-heading3 t-color'>Features & Benefits</h3>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-luxury', title: 'Luxurious'},
                        {spriteID: 'icon-durable', title: 'Durable'},
                        {spriteID: 'icon-supportive', title: 'Supportive'},
                        {spriteID: 'icon-breathable-mattress', title: 'Breathable'}
                    ]}/>
                    <ul>
                        <li>Luxurious 3" Euro pillow top is available in three firmness options for cushioned comfort and instant pressure relief</li>
                        <li>Durable and responsive dual-coil design contours to your curves to relieve pressure points, allows great airflow, and prevents sagging over time</li>
                        <li>Patented Lumbar Zone® Technology supports healthy spinal alignment to help relieve back pain</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Firmness</h3>
                    <div>
                        <p class='u-paddingBottom--lg'>Available in three firmness options:</p>
                        <div class={styles.accordionTitleContainerTitle}>
                            <UI.FirmnessBlock spriteID='icon-firmness-3' title='Plush Soft' afterTitle='(3/10)'>
                                <ul>
                                    <li>Contouring, body-hugging feel </li>
                                    <li>Best suited for side sleepers and lighter weight sleepers</li>
                                </ul>
                            </UI.FirmnessBlock>
                            <UI.FirmnessBlock spriteID='icon-firmness-5-7' title='Luxury Firm' afterTitle='(5-7/10)'>
                                <ul>
                                    <li>Our most popular comfort level</li>
                                    <li>Ideal for couples with different firmness preferences and sleep styles</li>
                                    <li>Best suited for back sleepers, but also great for stomach, and side sleepers</li>
                                </ul>
                            </UI.FirmnessBlock>
                            <UI.FirmnessBlock spriteID='icon-firmness-8' title='Firm' afterTitle='(8/10)'>
                                <ul>
                                    <li>"Floating on top" feel</li>
                                    <li>Best suited for stomach sleepers, but also great for back sleepers who prefer a very firm mattress</li>
                                    <li>Ideal for heavier weight sleepers 250-300 pounds</li>
                                </ul>
                            </UI.FirmnessBlock>
                        </div>
                    </div>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Certifications & Awards</h3>
                    <ul>
                        <li>{SHARED_FAQ_LINKS.CENTIPUR_MEMORY_FOAM(ScrollToFaqsAndSpecsButton)}</li>
                        <li>Congress of Chiropractic State Association Seal of Approval</li>
                        <li>Endorsed by the American Chiropractic Association</li>
                        <li>{SHARED_FAQ_LINKS.GREENGUARD(ScrollToFaqsAndSpecsButton)}</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Mattress Foundation with Universal Frame',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <h3 className='t-heading3 t-color'>Features & Benefits</h3>
                    <ul>
                        <li>Handcrafted from North American spruce, making it more durable than a traditional box spring</li>
                        <li>Rounded corners create a seamless look with your Saatva mattress</li>
                        <li>Steel Universal Frame fits all sizes to support and lift your foundation and mattress 7.5" off the ground</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Certifications & Awards</h3>
                    <ul>
                        <li>{SHARED_FAQ_LINKS.CERTIPUR(ScrollToFaqsAndSpecsButton)}</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Organic Mattress Pad',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <h3 className='t-heading3 t-color'>Features & Benefits</h3>
                    <ul>
                        <li>100% organic cotton wicks away moisture</li>
                        <li>Organic cotton blend side panels with an extra-wide elastic band to keep the mattress pad in place</li>
                        <li>Fits mattresses up to 16" high</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Certifications & Awards</h3>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-gots-cu-808012'
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.GOTS_COTTON(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.FTS_COTTON(ScrollToFaqsAndSpecsButton)}</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.SATEEN_SHEETS_LATEX_PILLOW]: [
        {
            heading: 'Organic Sateen Sheet Set',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <h3 className='t-heading3 t-color'>Features & Benefits</h3>
                    <ul>
                        <li>Full size and larger includes one flat sheet, one fitted sheet, and two pillowcases</li>
                        <li>Deep pockets for mattresses up to 16" high</li>
                        <li>Pairs well with the {anchorLink('Organic Sateen Duvet Cover Set', `/bedding/${PC.ORGANIC_SATEEN_DUVET_COVER_SET}`)}</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Ideal for</h3>
                    <ul>
                        <li>A silky-soft feel that maintains breathability</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Materials & Care Instructions</h3>
                    <ul>
                        <li>300 thread count organic long staple cotton</li>
                        <li>Machine wash cold with like colors in gentle cycle, do not bleach</li>
                        <li>Tumble dry on low</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Certifications</h3>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-gots-cu-808664'
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.GOTS_COTTON(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.FTS_COTTON(ScrollToFaqsAndSpecsButton)}</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Saatva Latex Pillow',
            getContent: () => (
                <div>
                    <h3 className='t-heading3 t-color'>Features & Benefits</h3>
                    <ul>
                        <li>Shredded natural latex core for responsive head and neck support</li>
                        <li>Removable & machine washable fill layer is covered in organic cotton and filled with down-like microdenier fibers (shredded latex core is not machine washable)</li>
                        <li><strong>Standard Loft</strong> fill is roughly 4-5" in height, perfect for back & stomach sleepers, or those who prefer a lower pillow height</li>
                        <li><strong>High Loft</strong> fill is roughly 6-7" in height, perfect for side & combination sleepers, or those who prefer a higher, plusher pillow</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Ideal for</h3>
                    <ul>
                        <li>All sleep positions</li>
                        <li>Those who want responsive support</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Materials & Care Instructions</h3>
                    <ul>
                        <li>Organic cotton cover with microdenier fiber fill & shredded natural latex foam core</li>
                        <li>Unzip the cotton cover, set aside the shredded latex core, and machine wash the cover and outer fill layer only (DO NOT wash the shredded latex core)</li>
                        <li>Tumble dry the fill layer on medium heat and hang the cotton cover to dry for best results</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.SATEEN_SHEETS_LATEX_PILLOW_PAD]: [
        {
            heading: 'Organic Sateen Sheet Set',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <h3 className='t-heading3 t-color'>Features & Benefits</h3>
                    <ul>
                        <li>Full size and larger includes one flat sheet, one fitted sheet, and two pillowcases</li>
                        <li>Deep pockets for mattresses up to 16" high</li>
                        <li>Pairs well with the {anchorLink('Organic Sateen Duvet Cover Set', `/bedding/${PC.ORGANIC_SATEEN_DUVET_COVER_SET}`)}</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Ideal for</h3>
                    <ul>
                        <li>A silky-soft feel that maintains breathability</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Materials & Care Instructions</h3>
                    <ul>
                        <li>300 thread count organic long staple cotton</li>
                        <li>Machine wash cold with like colors in gentle cycle, do not bleach</li>
                        <li>Tumble dry on low</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Certifications</h3>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-gots-cu-808664'
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.GOTS_COTTON(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.FTS_COTTON(ScrollToFaqsAndSpecsButton)}</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Saatva Latex Pillow',
            getContent: () => (
                <div>
                    <h3 className='t-heading3 t-color'>Features & Benefits</h3>
                    <ul>
                        <li>Shredded natural latex core for responsive head and neck support</li>
                        <li>Removable & machine washable fill layer is covered in organic cotton and filled with down-like microdenier fibers (shredded latex core is not machine washable)</li>
                        <li><strong>Standard Loft</strong> fill is roughly 4-5" in height, perfect for back & stomach sleepers, or those who prefer a lower pillow height</li>
                        <li><strong>High Loft</strong> fill is roughly 6-7" in height, perfect for side & combination sleepers, or those who prefer a higher, plusher pillow</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Ideal for</h3>
                    <ul>
                        <li>All sleep positions</li>
                        <li>Those who want responsive support</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Materials & Care Instructions</h3>
                    <ul>
                        <li>Organic cotton cover with microdenier fiber fill & shredded natural latex foam core</li>
                        <li>Unzip the cotton cover, set aside the shredded latex core, and machine wash the cover and outer fill layer only (DO NOT wash the shredded latex core)</li>
                        <li>Tumble dry the fill layer on medium heat and hang the cotton cover to dry for best results</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Organic Mattress Pad',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <h3 className='t-heading3 t-color'>Features & Benefits</h3>
                    <ul>
                        <li>100% organic cotton wicks away moisture</li>
                        <li>Organic cotton blend side panels with an extra-wide elastic band to keep the mattress pad in place</li>
                        <li>Fits mattresses up to 16" high</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Ideal for</h3>
                    <ul>
                        <li>All sleep positions</li>
                        <li>Those who want a pillow with responsive support</li>
                        <li>Added protection against daily wear and tear and light stains</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Materials & Care Instructions</h3>
                    <ul>
                        <li>Organic cotton, 4% spandex, 96% organic cotton side panels and silicone elastic band</li>
                        <li>Machine wash cold, gentle cycle, no bleach, tumble dry</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Certifications</h3>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-gots-cu-808012'
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.GOTS_COTTON(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.FTS_COTTON(ScrollToFaqsAndSpecsButton)}</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.SATEEN_SHEETS_MEMORY_FOAM_PILLOW]: [
        {
            heading: 'Organic Sateen Sheet Set',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <h3 className='t-heading3 t-color'>Features & Benefits</h3>
                    <ul>
                        <li>Full size and larger includes one flat sheet, one fitted sheet, and two pillowcases</li>
                        <li>Deep pockets for mattresses up to 16" high</li>
                        <li>Pairs well with the {anchorLink('Organic Sateen Duvet Cover Set', `/bedding/${PC.ORGANIC_SATEEN_DUVET_COVER_SET}`)}</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Ideal for</h3>
                    <ul>
                        <li>A silky-soft feel that maintains breathability</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Materials & Care Instructions</h3>
                    <ul>
                        <li>300 thread count organic long staple cotton</li>
                        <li>Machine wash cold with like colors in gentle cycle, do not bleach</li>
                        <li>Tumble dry on low</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Certifications</h3>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-gots-cu-808664'
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.GOTS_COTTON(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.FTS_COTTON(ScrollToFaqsAndSpecsButton)}</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Graphite Memory Foam Pillow',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <h3 className='t-heading3 t-color'>Features & Benefits</h3>
                    <ul>
                        <li>Advanced graphite technology draws excess heat away</li>
                        <li>Breathable shredded memory foam & latex core provides contouring support</li>
                        <li>Removable & machine-washable knit cover is made with breathable organic cotton</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Ideal for</h3>
                    <ul>
                        <li>All sleep positions</li>
                        <li>Those who want responsive support</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Materials & Care Instructions</h3>
                    <ul>
                        <li>Organic cotton blend knit cover, graphite-infused memory foam, shredded memory foam and natural latex foam core</li>
                        <li>Unzip and remove the knit cotton cover from the pillow and machine wash cold in the delicate cycle, tumble dry low or hang to dry</li>
                        <li>Spot clean the surface of the inner pillow as needed</li>
                    </ul>

                    <h3 className='t-heading3 t-color u-marginTop--lg'>Certifications</h3>
                    <ul>
                        <li>{SHARED_FAQ_LINKS.CERTIPUR(ScrollToFaqsAndSpecsButton)}</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.BUNK_TRUNDLE_YOUTH]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-lumbar-support', title: 'Back Support'},
                        {spriteID: 'icon-durable', title: 'Durable'},
                        {spriteID: 'icon-breathable-mattress', title: 'Breathable'},
                        {spriteID: 'icon-healthier-sleep', title: 'Healthier sleep'}
                    ]}/>
                    <ul>
                        <li>Low-profile 6” height works seamlessly with most bunk beds and trundle beds</li>
                        <li>Lumbar Zone® Quilting & gel-infused memory foam lumbar crown provide enhanced lower back suppor</li>
                        <li>Responsive recycled steel coils encased in foam prevents sagging</li>
                        <li>Treated with our exclusive Guardin™ botanical antimicrobial treatment to inhibit the growth of bacteria, mold, and mildew</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Firmness',
            subtitle: 'Flippable, dual-sided design',
            getContent: () => (
                <>
                    <UI.FirmnessBlock spriteID='icon-firmness-5-7' title='Medium (5-7/10)'>
                        <ul>
                            <li>Optimal comfort level for most sleep positions</li>
                            <li>Ideal for kids, teens, and even overnight guests</li>
                        </ul>
                    </UI.FirmnessBlock>
                </>
            )
        },
        {
            heading: 'Compatibility',
            getContent: () => (
                <div>
                    <p>Designed to use exclusively on bunk beds & trundle beds that typically require a lower mattress height</p>
                    <UI.CompatibleItems itemsData={COMPATIBILITY_BUNK_TRUNDLE} />
                </div>
            )
        },
        {
            heading: 'Certifications & Awards',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-certipur-us',
                        'icon-certification-greenguard',
                        'icon-certification-ccsa',
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.CERTIPUR(ScrollToFaqsAndSpecsButton)}</li>
                        <li>{SHARED_FAQ_LINKS.GREENGUARD_LATEX(ScrollToFaqsAndSpecsButton)}</li>
                        <li>Congress of Chiropractic State Association Seal of Approval</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.MURPHY_BED_MATTRESS]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-lumbar-support', title: 'Back Support'},
                        {spriteID: 'icon-durable', title: 'Durable'},
                        {spriteID: 'icon-breathable-mattress', title: 'Breathable'},
                        {spriteID: 'icon-healthier-sleep', title: 'Healthier sleep'}
                    ]}/>
                    <ul>
                        <li>Unique foam-encased coils and a hand-tufted construction help prevent mattress layers from shifting over time from upright Murphy bed storage</li>
                        <li>Low-profile 10” height works seamlessly with all Murphy beds</li>
                        <li>Cover made with naturally hypoallergenic and breathable organic cotton for cooler sleep</li>
                        <li>Patented Lumbar Zone® Technology supports healthy spinal alignment to help relieve back pain</li>
                        <li>Exclusive Guardin™ botanical antimicrobial treatment inhibits the growth of bacteria, mold, and mildew</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Firmness',
            subtitle: 'Flippable, dual-sided design',
            getContent: () => (
                <>
                    <UI.FirmnessBlock spriteID='icon-firmness-5-7' title='Medium (5-7/10)'>
                        <ul>
                            <li>Optimal comfort level for most sleep positions</li>
                            <li>Ideal for kids, teens, and even overnight guests</li>
                        </ul>
                    </UI.FirmnessBlock>
                </>
            )
        },
        {
            heading: 'Compatibility',
            getContent: () => (
                <div>
                    <p>Designed to be used exclusively on Murphy beds (sometimes referred to as wall beds or pull-down beds)</p>
                    <UI.Disclaimer>
                        Murphy Bed Mattress is NOT compatible with any adjustable bases.
                    </UI.Disclaimer>
                </div>
            )
        },
        {
            heading: 'Certifications & Awards',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-certipur-us',
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.CERTIPUR(ScrollToFaqsAndSpecsButton)}</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.SIGNATURE_MATTRESS_PAD]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-breathable', title: 'Breathable protection'},
                        {spriteID: 'icon-fit', title: 'Secure fit'},
                        {spriteID: 'icon-healthier-sleep', title: 'Healthier sleep'},
                    ]}/>
                    <ul>
                        <li>4" box quilt ensures poly-fiber fill is evenly distributed</li>
                        <li>Percale cotton weave in a 233 thread count on top and 180 thread count on the reverse side to maximize breathability</li>
                        <li>Food-grade, non-rubbery polyurethane moisture barrier between two layers of cotton</li>
                        <li>Knit elastic deep pocket side panels with thick bands to keep it in place on mattresses up to 17" high</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Ideal for',
            getContent: () => (
                <>
                    <ul>
                        <li>Kids’ bedrooms and households with pets</li>
                        <li>Adding longevity to your mattress investment</li>
                        <li>Daily protection against liquids, stains, allergens, and light humidity</li>
                    </ul>
                </>
            )
        },
        {
            heading: 'Materials & care instructions',
            getContent: () => (
                <ul>
                    <li>100% percale cotton top and bottom layers</li>
                    <li>92% polyester, 8% spandex side panels</li>
                    <li>Silicone elastic band</li>
                    <li>Machine wash cold, gentle cycle, no bleach, tumble dry</li>
                </ul>
            )
        },
        {
            heading: 'Certifications & Awards',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-oeko-tex-100',
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.OEKO(ScrollToFaqsAndSpecsButton)}</li>
                    </ul>
                </div>
            )
        }
    ],
    // BEDDING 
    [PC.MEMORY_FOAM_PILLOW]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-contouring', title: 'Contouring'},
                        {spriteID: 'icon-cooling', title: 'Cooling'},
                        {spriteID: 'icon-breathable', title: 'Breathable'}
                    ]}/>
                    <ul>
                        <li>Advanced graphite technology draws excess heat away</li>
                        <li>Breathable shredded memory foam & latex core provides contouring support</li>
                        <li>Removable & machine-washable knit cover is made with breathable organic cotton</li>
                        <li><strong>Standard Loft</strong> fill is roughly 4-5” in height, perfect for back & stomach sleepers, or those who prefer a lower pillow height</li>
                        <li><strong>High Loft</strong> fill is roughly 6-7” in height, perfect for side & combination sleepers, or those who prefer a higher, plusher pillow </li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Ideal For',
            getContent: () => (
                <div>
                    <ul>
                        <li>All sleep positions</li>
                        <li>Hot sleepers</li>
                        <li>Those who want contouring support</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Certifications',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-certipur-us',
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.CERTIPUR(ScrollToFaqsAndSpecsButton)}</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Materials & care instructions',
            getContent: () => (
                <div>
                    <ul>
                        <li>Organic cotton blend knit cover</li>
                        <li>Graphite-infused memory foam</li>
                        <li>Shredded memory foam and natural latex foam core</li>
                        <li>Unzip and remove the knit cotton cover from the pillow and machine wash cold in the delicate cycle</li>
                        <li>Tumble dry low or hang to dry</li>
                        <li>Spot clean the surface of the inner pillow as needed</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.DOWN_ALTERNATIVE_PILLOW]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-soft', title: 'Plush'},
                        {spriteID: 'icon-breathable', title: 'Breathable'},
                        {spriteID: 'icon-hypoallergenic', title: 'Hypoallergenic'}
                    ]}/>
                    <ul>
                        <li>Plush blend of microdenier down alternative fibers and memory fiber clusters for a soft yet supportive feel</li>
                        <li>100% hypoallergenic for sensitive sleepers</li>
                        <li><strong>Standard Loft</strong> fill is roughly 4-5” in height, perfect for back & stomach sleepers, or those who prefer a lower pillow height</li>
                        <li><strong>High Loft</strong> fill is roughly 6-7” in height, perfect for side & combination sleepers, or those who prefer a higher, plusher pillow </li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Ideal For',
            getContent: () => (
                <div>
                    <ul>
                        <li>All sleep positions</li>
                        <li>Allergy sufferers</li>
                        <li>Those who prefer an airy, lightweight pillow</li>
                        <li>A cruelty-free and hypoallergenic alternative to traditional goose down pillows</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Materials & care instructions',
            getContent: () => (
                <div>
                    <ul>
                        <li>Organic cotton cover</li>
                        <li>Microdenier down alternative and memory fiber fill</li>
                        <li>Spot clean as needed</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.CLOUD_MEMORY_FOAM_PILLOW]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-contouring', title: 'Contouring'},
                        {spriteID: 'icon-lumbar-support', title: 'Supportive'},
                        {spriteID: 'icon-breathable', title: 'Breathable'}
                    ]}/>
                    <ul>
                        <li>Specially engineered blend of shredded gel-infused memory foam & buoyant latex foam offers best-in-class contouring support</li>
                        <li>Superbly breathable and temperature neutral to keep even hot sleepers comfortable</li>
                        <li>The addition of latex foam in the fill helps keep its loft and shape better than other shredded memory foam pillows</li>
                        <li>Removable & machine-washable knit cover is made with breathable organic cotton</li>
                        <li><strong>Standard Loft</strong> fill is roughly 4-5” in height, perfect for back & stomach sleepers, or those who prefer a lower pillow height</li>
                        <li><strong>High Loft</strong>  fill is roughly 6-7” in height, perfect for side & combination sleepers, or those who prefer a higher, plusher pillow </li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Ideal For',
            getContent: () => (
                <div>
                    <ul>
                        <li>All sleep positions</li>
                        <li>Those who want the feel of a memory foam pillow and the responsiveness of latex foam</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Materials & care instructions',
            getContent: () => (
                <div>
                    <ul>
                        <li>Gel-infused memory foam </li>
                        <li>Natural latex foam</li>
                        <li>Organic cotton blend knit cover</li>
                        <li>Unzip and remove the cover from the pillow and machine wash cold in the delicate cycle</li>
                        <li>Tumble dry low or hang to dry</li>
                        <li>Spot clean the surface of the inner pillow as needed</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Certifications',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-certipur-us',
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.CERTIPUR(ScrollToFaqsAndSpecsButton)}</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.ORGANIC_QUILTED_PILLOW]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-sustainability', title: 'Organic'},
                        {spriteID: 'icon-durable', title: 'Durable'},
                        {spriteID: 'icon-breathable', title: 'Breathable'}
                    ]}/>
                    <ul>
                        <li>Superbly breathable, moisture-wicking, antimicrobial & 100% hypoallergenic</li>
                        <li>Machine-washable organic cotton knit cover quilted with organic New Zealand wool for extra plushness</li>
                        <li>Shredded natural latex for an ideal combination of durability, responsiveness, and cushiony softness</li>
                        <li><strong>Standard Loft</strong> fill is roughly 4-5” in height, perfect for back & stomach sleepers, or those who prefer a lower pillow height</li>
                        <li><strong>High Loft</strong> fill is roughly 6-7” in height, perfect for side & combination sleepers, or those who prefer a higher, plusher pillow </li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Ideal For',
            getContent: () => (
                <div>
                    <ul>
                        <li>All sleep positions</li>
                        <li>Those who want an all-organic pillow</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Materials & care instructions',
            getContent: () => (
                <div>
                    <ul>
                        <li>Organic natural latex foam</li>
                        <li>Organic wool</li>
                        <li>Organic cotton knit cover</li>
                        <li>Unzip and remove the organic cotton cover from the pillow and machine wash cold in the delicate cycle</li>
                        <li>Tumble dry low or hang to dry</li>
                        <li>Spot clean the surface of the inner pillow as needed</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Certifications & Awards',
            getContent: (ScrollToFaqsAndSpecsButton) => (
                <div>
                    <UI.CertificationLogos imageIDs={[
                        'icon-certification-gots',
                    ]} />
                    <ul>
                        <li>{SHARED_FAQ_LINKS.GOTS(ScrollToFaqsAndSpecsButton)}</li>
                    </ul>
                </div>
            )
        }
    ],
    [PC.THE_SAATVA_PILLOW]: [
        {
            heading: 'Features & Benefits',
            getContent: () => (
                <div>
                    <UI.FeatureIcons featuresData={[
                        {spriteID: 'icon-soft', title: 'Plush'},
                        {spriteID: 'icon-durable', title: 'Durable'},
                        {spriteID: 'icon-breathable', title: 'Breathable'}
                    ]}/>
                    <ul>
                        <li>Shredded natural latex core for responsive head and neck support</li>
                        <li>Removable & machine washable fill layer is covered in organic cotton and filled with down-like microdenier fibers (shredded latex core is not machine washable)
                        </li>
                        <li>Shredded natural latex for an ideal combination of durability, responsiveness, and cushiony softness</li>
                        <li><strong>Standard Loft</strong> fill is roughly 4-5” in height, perfect for back & stomach sleepers, or those who prefer a lower pillow height</li>
                        <li><strong>High Loft</strong> fill is roughly 6-7” in height, perfect for side & combination sleepers, or those who prefer a higher, plusher pillow</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Ideal For',
            getContent: () => (
                <div>
                    <ul>
                        <li>All sleep positions</li>
                        <li>Those who want responsive support</li>
                    </ul>
                </div>
            )
        },
        {
            heading: 'Materials & care instructions',
            getContent: () => (
                <div>
                    <ul>
                        <li>Organic cotton cover</li>
                        <li>Microdenier fiber fill</li>
                        <li>Shredded natural latex foam core</li>
                        <li>Unzip the cotton cover, set aside the shredded latex core, and machine wash the cover and outer fill layer only (DO NOT wash the shredded latex core)</li>
                        <li>Tumble dry the fill layer on medium heat and hang the cotton cover to dry for best results</li>
                        <li>We recommend washing your pillow twice a year and replacing every 3 years</li>
                    </ul>
                </div>
            )
        }
    ]
}

export default config
